import './RecentBlocks.css'
import React from 'react';
import {Block} from '../interfaces.js';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import ago from 's-ago';
import {Link} from 'react-router-dom';
import { url } from '../util';

interface RecentBlocksState {
    blocks: Block[],
    isLoading: boolean,
}

class RecentBlocks extends React.Component<{}, RecentBlocksState> {
    constructor(props: {}) {
        super(props)
        this.state = {
            blocks: [],
            isLoading: false,
        }
    }

    componentDidMount() {
        this.showMore();
    }

    showMore() {
        this.setState(state => {
            return {
                ...state,
                isLoading: true,
            };
        });

        const params = this.state.blocks.length === 0 ? {} : {until: this.state.blocks[this.state.blocks.length - 1].height}

        axios.get(url('/api/blocks?'), {params}).then(response => {
            this.setState({
                isLoading: false,
                blocks: this.state.blocks.concat(response.data.blocks)
            })
        })

    }

    showMoreButton(): React.ReactElement {
        if (this.state.isLoading) {
            return <div className='Loading'>
                <Loader type="ThreeDots" color="lightblue" height={25}/>
            </div>;
        } else if (this.state.blocks.length > 0 && this.state.blocks[this.state.blocks.length-1].height != 0) {
            return <div className='ShowMore' onClick={_ => this.showMore()}>Show More</div>
        } else {
            return <div/>
        }
    }
    
    refresh() {
        this.setState(state => {
            return {
                ...state,
                isLoading: true, 
            }
        })

        axios.get(url('/api/blocks?')).then(response => {
            this.setState({
                isLoading: false,
                blocks: response.data.blocks
            })
        })
    }

    render(): React.ReactElement {
        return <div className='RecentBlocks Panel'>
            <header onClick={_ => this.refresh()}>
                Recent Blocks
            </header>
            <div className='Scrollable'>
                <table>
                    <thead>
                        <tr>
                            <th>Height</th>
                            <th>Block Id</th>
                            <th>Miner</th>
                            <th>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.blocks.map(block => {
                                const date = new Date(block.timestamp * 1000);
                                const truncatedId = block.block_id.substr(0, 16) + "..."
                                const truncatedMiner = block.miner.substr(0, 16) + "..."

                                return <tr key={block.height}>
                                    <td className='Height'>{block.height}</td>
                                    <td className='BlockId'><Link to={`/block/${block.height}`}>{truncatedId}</Link></td>
                                    <td className='Miner'><Link to={`/address/${block.miner}`}>{truncatedMiner}</Link></td>
                                    <td className='Time' title={date.toString()}>{ago(date)}</td>
                                </tr>;
                            })
                        }
                    </tbody>
                </table>
                <div className='ShowMoreContainer'>
                {
                    this.showMoreButton()
                }
                </div>
            </div>
        </div>
    }
}

export default RecentBlocks;