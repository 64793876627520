import './App.css';
import RecentBlocks from './components/RecentBlocks';
import TopMiners from './components/TopMiners';
import {BrowserRouter as Router, Route, Switch, useParams, Link} from 'react-router-dom';
import BlockDetails from './components/BlockDetails';
import AddressDetails from './components/AddressDetails';
import DifficultyGraph from './components/DifficultyGraph';
import TransactionDetails from './components/TransactionDetails';
import Mempool from './components/Mempool';
import Faucet from './components/Faucet';

interface BlockParams {
  height: string
}

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-Header">
          <Link to="/">Zimcoin Explorer</Link>
          <div className="FaucetLink">
            <Link to="/faucet">Request Zimcoins</Link>
          </div>
        </header>
        <Switch>
          <Route exact path="/">
            <RecentBlocks />
            <Mempool />
            <DifficultyGraph />
            <TopMiners />

          </Route>
          <Route path="/block/:height">
            { <BlockDetailsPane/>}
          </Route>
          <Route path="/address/:address">
            { <AddressDetailsPane />}
          </Route>
          <Route path="/transaction/:txid">
            {<TransactionDetailsPane />}
          </Route>
          <Route path="/faucet">
            <Faucet />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function BlockDetailsPane() {
  const {height} = useParams<BlockParams>();
  return <BlockDetails height={+height}/>;
}

function AddressDetailsPane() {
  const {address} = useParams<{address: string}>();
  return <AddressDetails address={address}/>;
}

function TransactionDetailsPane() {
  const {txid} = useParams<{txid: string}>();
  return <TransactionDetails txid={txid} />;
}

export default App;
