import './AddressDetails.css'
import React from 'react';
import {AddressSummary} from '../interfaces';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import { url } from '../util';

interface AddressDetailsProps {
    address: string,
}

interface AddressDetailsState {
    addressSummary?: AddressSummary
}

class AddressDetails extends React.Component<AddressDetailsProps, AddressDetailsState> {
    constructor(props: AddressDetailsProps) {
        super(props);

        this.state = {};
    }

    loadData() {
        axios
            .get(url('/api/address'), {params: {address: this.props.address}})
            .then(response => {
                this.setState({
                    addressSummary: response.data
                })
            })
    }

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: AddressDetailsProps) {
        if (this.props.address !== prevProps.address) {
            this.loadData();
        }
    }

    render() {
        const summary = this.state.addressSummary;
        if (summary === undefined) {
            return <div className='AddressDetails Panel'>
                <Loader type='ThreeDots'/>
            </div>
        } else {
            return <div className='AddressDetails Panel'>
                <div className='Detail'> 
                    <span className='DetailName'>Address</span>
                    <span className='DetailValue Mono'>{this.props.address}</span>
                </div>
                <div className='Detail'> 
                    <span className='DetailName'>Balance</span>
                    <span className='DetailValue Mono'>{summary.balance}</span>
                </div>
                <div className='Detail'> 
                    <span className='DetailName'>Nonce</span>
                    <span className='DetailValue Mono'>{summary.nonce}</span>
                </div>
            </div>
        }
        
    }
}

export default AddressDetails;