import React from 'react';
import {Transaction} from '../interfaces';
import axios from 'axios';
import { url } from '../util';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import TransactionList from './TransactionList';
import './Mempool.css';

interface MempoolState {
    transactions?: Transaction[],
}

class Mempool extends React.Component<{}, MempoolState> {
    constructor(props: {}) {
        super(props);

        this.state = {};
    }
    loadData() {
        axios.get(url('/api/mempool'))
            .then(response => {
                this.setState({ transactions: response.data.transactions });
            })
    }

    componentDidMount() {
        this.loadData()
    }

    content() {
        if (this.state.transactions === undefined) {
            return <Loader type='ThreeDots'/>
        }
        if (this.state.transactions.length === 0) {
            return <div className='Scrollable'>No transactions in Mempool</div>;
        }
        return <div className='Scrollable'>
                <TransactionList transactions={this.state.transactions}/>
            </div>
    }

    render() {
        return <div className='Mempool Panel'>
            <header onClick={_ => this.loadData()}>
                Mempool
            </header>
            { this.content() }
        </div>
    }
}

export default Mempool;