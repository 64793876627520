import './TransactionList.css'
import { Transaction } from '../interfaces';
import { Link } from 'react-router-dom';
import { intComma } from 'humanize-plus';

interface TransactionListProps {
    transactions: Transaction[],
}

function TransactionList(props: TransactionListProps) {
    return <table className='TransactionList'>
        <thead>
            <tr>
                <th>Txid</th>
                <th>Sender</th>
                <th>Recipient</th>
                <th>Amount</th>
            </tr>
        </thead>
        <tbody>
            {
                props.transactions.map(transaction => {
                    const truncatedId = transaction.txid.substr(0, 12) + "..."
                    const truncatedSender = transaction.sender_hash.substr(0, 10) + "..."
                    const truncatedRecipient = transaction.recipient_hash.substr(0, 10) + "..."

                    return <tr key={transaction.txid}>
                        <td className='TxId Mono'><Link to={`/transaction/${transaction.txid}`}>{truncatedId}</Link></td>
                        <td className='Sender Mono'><Link to={`/address/${transaction.sender_hash}`}>{truncatedSender}</Link></td>
                        <td className='Recipient Mono'><Link to={`/address/${transaction.recipient_hash}`}>{truncatedRecipient}</Link></td>
                        <td className='Amount Mono'>{intComma(transaction.amount)}</td>
                    </tr>;
                })
            }
        </tbody>
    </table>
}

export default TransactionList;