import './BlockDetails.css'
import React from 'react';
import {Block} from '../interfaces.js';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import ago from 's-ago';
import { Link } from 'react-router-dom';
import { compactInteger, intComma } from 'humanize-plus';
import { url } from '../util';
import TransactionList from './TransactionList';

interface BlockDetailsProps {
    height: number,
}

interface BlockDetailsState {
    block?: Block,
}

class BlockDetails extends React.Component<BlockDetailsProps, BlockDetailsState> {
    constructor(props: BlockDetailsProps) {
        super(props);
        this.state = { block: undefined };
    }

    loadData() {
        const params = {until: this.props.height+1, limit: 1};

        axios.get(url('/api/blocks'), {params}).then(response => {
            const blocks: Block[] = response.data.blocks;
            if (blocks.length !== 1 || blocks[0].height !== this.props.height) {
                return;
            } else {
                this.setState({
                    block: blocks[0],
                })
            }
        })
    }

    componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevProps: BlockDetailsProps) {
        if (prevProps.height !== this.props.height) {
            this.loadData();
        }
    }

    render() {
        const block = this.state.block;

        if (block === undefined) {
            return <div className='BlockDetails Panel'>
                <Loader type='ThreeDots'/>
            </div>;
        }
        const date = new Date(block.timestamp * 1000);

        return <div className='BlockDetails Panel'>
            <div className='Detail'> 
                <span className='DetailName'>Block Id</span>
                <span className='DetailValue Mono'>{block.block_id}</span>
            </div>
            <div className='Detail'> 
                <span className='DetailName'>Height</span>
                <span className='DetailValue Mono'>{block.height}</span>
            </div>
            <div className='Detail'> 
                <span className='DetailName'>Previous Block</span>
                <span className='DetailValue Mono'><Link to={`/block/${block.height - 1}`}>{block.previous}</Link></span>
            </div>
            <div className='Detail'> 
                <span className='DetailName'>Mined at</span>
                <span className='DetailValue'>{`${date.toUTCString()} (${ago(date)})`}</span>
            </div>
            <div className='Detail'> 
                <span className='DetailName'>Difficulty</span>
                <span className='DetailValue' title={""+block.difficulty}>{compactInteger(block.difficulty, 2)}</span>
            </div>
            <div className='Detail'> 
                <span className='DetailName'>Nonce</span>
                <span className='DetailValue Mono'>{intComma(block.nonce)}</span>
            </div>
            { block.transactions.length > 0 && <TransactionList transactions={block.transactions}/> }
        </div>
    }
}

export default BlockDetails;