import './DifficultyGraph.css';
import React from 'react';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import {TimeSeries, Event, timeSeries, TimeEvent} from 'pondjs';
import { url } from '../util';

import {
    Charts,
    ChartContainer,
    ChartRow,
    YAxis,
    LineChart
} from "react-timeseries-charts";


interface DifficultyGraphState {
    difficulties?: any,
    timerange?: any,
}

class DifficultyGraph extends React.Component<{}, DifficultyGraphState> {
    constructor(props: {}) {
        super(props);

        this.state = {}
    }

    loadData() {
        axios
            .get(url('/api/difficulties'))
            .then(response => {
                const data: [number, number][] = response.data.difficulties;
                const series = new TimeSeries({
                    name: 'difficulty',
                    columns: ['time', 'difficulty'],
                    events: data.map(([time, difficulty]) => new TimeEvent(time * 1000, difficulty))
                });
                this.setState({
                    difficulties: series,
                    timerange: series.timerange()
                })
            })

    }

    componentDidMount() {
        this.loadData();
    }

    handleTimeRangeChanged(timerange: any) {
        this.setState(state => ({
            ...state,
            timerange
        }));
    }

    render() {
        const series = this.state.difficulties;
        if (series === undefined) {
            return <div className='DifficultyGraph Panel'>
                <Loader type='ThreeDots'/>
            </div>;
        }

        return <div className='DifficultyGraph Panel'>
            <header>
                Difficulty Graph
            </header>
            <ChartContainer timeRange={this.state.timerange} width={750} enablePanZoom onTimeRangeChanged={(timerange: any) => this.handleTimeRangeChanged(timerange)}>
                <ChartRow height={315}>
                    <YAxis id='axis1' label='Diff' type='log' min={series.min()} max={series.max()}/>
                    <Charts>
                        <LineChart axis='axis1' series={series} column='difficulty'/>
                    </Charts>
                </ChartRow>
            </ChartContainer>
        </div>;
    }
}

export default DifficultyGraph;