import './TransactionDetails.css'
import React from 'react';
import { Transaction, Block } from '../interfaces';
import axios from 'axios';
import { url } from '../util';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { intComma } from 'humanize-plus';
import ago from 's-ago';

interface TransactionDetailsProps {
    txid: string,
}

interface TransactionDetailsState {
    transaction?: Transaction,
    block?: Block
}

class TransactionDetails extends React.Component<TransactionDetailsProps, TransactionDetailsState> {
    constructor(props: TransactionDetailsProps) {
        super(props);
        this.state = {};
    }

    loadData() {
        const params = {txid: this.props.txid};

        axios.get(url('/api/transaction'), {params}).then(response => {
            this.setState({
                transaction: response.data.transaction,
                block: response.data.block,
            })
        })
    }

    componentDidMount() {
        this.loadData()
    }

    componentDidUpdate(prevProps: TransactionDetailsProps) {
        if (prevProps.txid !== this.props.txid) {
            this.loadData();
        }
    }

    transactionStateInfo() {
        if (!this.state.block) {
            return <div className='Detail'> 
                <span className='DetailName'>State</span>
                <span className='DetailValue'>Unconfirmed</span>
            </div>
        }
        const date = new Date(1000 * this.state.block.timestamp)
        return [
            <div className='Detail'> 
                <span className='DetailName'>State</span>
                <span className='DetailValue'>Confirmed {ago(date)}</span>
            </div>,
            <div className='Detail'> 
                <span className='DetailName'>Block</span>
                <span className='DetailValue Mono'><Link to={`/block/${this.state.block.height}`}>{this.state.block.block_id}</Link></span>
            </div>,
        ]
    }

    render() {
        const transaction = this.state.transaction;
        const block = this.state.block;

        if (transaction === undefined) {
            return <div className='TransactionDetails Panel'>
                <Loader type='ThreeDots'/>
            </div>;
        }

        return <div className='TransactionDetails Panel'>
            <div className='Detail'> 
                <span className='DetailName'>Txid</span>
                <span className='DetailValue Mono'>{transaction.txid}</span>
            </div>
            <div className='Detail'> 
                <span className='DetailName'>Sender</span>
                <span className='DetailValue Mono'><Link to={`/address/${transaction.sender_hash}`}>{transaction.sender_hash}</Link></span>
            </div>
            <div className='Detail'> 
                <span className='DetailName'>Recipient</span>
                <span className='DetailValue Mono'><Link to={`/address/${transaction.recipient_hash}`}>{transaction.recipient_hash}</Link></span>
            </div>
            <div className='Detail'> 
                <span className='DetailName'>Amount</span>
                <span className='DetailValue Mono'>{intComma(transaction.amount)}</span>
            </div>
            <div className='Detail'> 
                <span className='DetailName'>Fee</span>
                <span className='DetailValue Mono'>{intComma(transaction.fee)}</span>
            </div>
            <div className='Detail'> 
                <span className='DetailName'>Nonce</span>
                <span className='DetailValue Mono'>{intComma(transaction.nonce)}</span>
            </div>
            { this.transactionStateInfo() }
        </div>
    }
}

export default TransactionDetails;