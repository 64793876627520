import './TopMiners.css'
import React from 'react';
import {Miner} from '../interfaces.js';
import axios from 'axios';
import Loader from 'react-loader-spinner';
import {Link} from 'react-router-dom';
import { url } from '../util';

interface TopMinersState {
    miners: Miner[],
    isLoading: boolean,
}

class TopMiners extends React.Component<{}, TopMinersState> {
    constructor(props: {}) {
        super(props);
        this.state = {miners: [], isLoading: true};
    }

    loadData() {
        axios.get(url('/api/miners')).then(response => {
            this.setState({miners: response.data.miners, isLoading: false})
        })
    }

    componentDidMount() {
        this.loadData();
    }

    refresh() {
        this.setState(state => {
            return {
                ...state,
                isLoading: true,
            }
        });

        this.loadData();
    }

    render() {
        const tableOrLoader = this.state.isLoading ? <Loader type='ThreeDots'/> :
            <table>
                <thead>
                    <tr>
                        <th>Miner</th>
                        <th>Count</th>
                    </tr>
                </thead>
                <tbody>
                {
                    this.state.miners.map(miner => {
                        const truncatedMiner = miner.address.substr(0, 16) + "..."
                        return <tr key={miner.address}>
                            <td className='Address'><Link to={`/address/${miner.address}`}>{truncatedMiner}</Link></td>
                            <td className='Count'>{miner.count}</td>
                        </tr>;
                    })
                }
                </tbody>
            </table>
        return <div className='TopMiners Panel'>
            <header onClick={_ => this.refresh()}>
                Top Miners
            </header>
            <div className='Scrollable'>
                { tableOrLoader }
            </div>
        </div>
    }
}

export default TopMiners;