import './Faucet.css';
import React, { FormEvent } from 'react';
import Loader from 'react-loader-spinner';
import axios from 'axios';
import { url } from '../util';
import { Transaction } from '../interfaces';
import { Link } from 'react-router-dom';

interface FaucetState {
    submitted: boolean,
    transaction?: Transaction,
    err?: string,
}

class Faucet extends React.Component<{}, FaucetState> {
    addressInput: React.RefObject<HTMLInputElement>;

    constructor(props: {}) {
        super(props);

        this.state = {
            submitted: false
        };
        this.addressInput = React.createRef();
    }

    handleSubmit(event: FormEvent) {
        event.preventDefault();
        const address = this.addressInput.current?.value || "";
        this.setState({
            submitted: true,
        })
        const params = { address };
        axios.post(url('/api/faucet'), null, {params})
            .then(response => {
                if (response.data.transaction) {
                    this.setState({
                        submitted: false,
                        transaction: response.data.transaction,
                    })
                } else {
                    this.setState({
                        submitted: false,
                        err: response.data.err,
                    })
                }
            });
    }

    render() {
        if (this.state.submitted) {
            return <div className='Faucet Panel'>
                <Loader type='ThreeDots'/>
            </div>;
        } else if (this.state.transaction) {
            const truncatedId = this.state.transaction.txid.substr(0, 16) + '...';
            return <div className='Faucet Panel Success'>
                Transaction <Link to={`/transaction/${this.state.transaction.txid}`}>{truncatedId}</Link> has been published to the network.
                <br/>
                Hopefully it will be confirmed soon.
            </div>;
        } else {
            return <div className='Faucet Panel'>
                This is for people who want to be able to interact with the network but do not have a CPU capable of mining blocks in a reasonable timeframe.
                <br/>
                <form onSubmit={event => this.handleSubmit(event)}>
                    <input ref={this.addressInput} className="Address Mono" type="string"/>
                    <input className="RequestButton" type='submit' value="Send me Zimcoins"/>
                </form>
                { this.state.err && <span className='Error'>{this.state.err}</span>}
            </div>;
        }
    }
}

export default Faucet;